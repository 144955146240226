$(function () {
    var $slide = $('.home-slide .swiper-container');

    if(!$slide.length) return

    // Slideshow
    $slide.find('.swiper-slide').each(function() {
        var item = $(this);
        var image = $(item).data('image');

        $(item).css({
            'backgroundImage': "url("+image+")"
        });
    });

    var mySwiper = new Swiper ($slide, {
        autoplay: {
            delay: 7000,
        },
        speed: 550,
        pagination: {
            el: '.slide-pagination',
            clickable: true,
            bulletClass: 'bullet',
            bulletActiveClass: 'bullet-active',
            modifierClass: 'slide-pagination-'
        }
    });

    $slide.mouseover(function() {
        mySwiper.autoplay.stop();
    }).mouseout(function() {
        mySwiper.autoplay.start();
    });
});