$(function() {

    // Mask Telephone
    var SPMaskBehavior = function (val) {
        return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
    },
    spOptions = {
        onKeyPress: function(val, e, field, options) {
            field.mask(SPMaskBehavior.apply({}, arguments), options);
        }
    };
    $('input[type="tel"]').mask(SPMaskBehavior, spOptions);

    // Submit Form
    $('html').on('submit', 'form.form-contact', function(e) {
        e.preventDefault();

        var form = $(this);
        var formData = form.serialize();
        var controller = form.attr('action');
        var $button = $(form).find('.button');
        var $loading = $(form).find('.loading');

        $button.addClass('hidden');
        $loading.removeClass('hidden');

        $.post(controller, {formData: formData}, function(data) {

            if(data.type == 'error') {
                $button.removeClass('hidden');
                $loading.addClass('hidden');

                $('.form-alert.error').html('<p>' + data.text + '</p>').removeClass('hidden').fadeIn();

                $('body, html').animate({
                    scrollTop: $('.form-alert.error').offset().top - 10
                }, 400);
            } else {
                $('.form-alert.error').html('').addClass('hidden').fadeOut();
            }

            if(data.type == 'success') {
                $button.removeClass('hidden');
                $loading.addClass('hidden');
                
                $('.box-form').remove();
                $('.form-alert.success').removeClass('hidden');

                $('body, html').animate({
                    scrollTop: $('.form-alert.success').offset().top - 10
                }, 400);
            }
        }, 'json');
    });
});