$(function() {
    var $btnTogleMenu = $('.btn-toggle-menu');

    if(!$btnTogleMenu.length) return

    var $toggleMenu = $('.toggle-menu');

    $btnTogleMenu.on('click', function(e) {

        e.preventDefault();

        $toggleMenu.slideToggle();
        $toggleMenu.addClass('mobile');
    });
});